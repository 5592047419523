import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import signature from "../images/signature.png"
import Youtube from '../components/youtube'

const IndexPage = () => (
  <>
    <SEO title="Home" keywords={[`Samuel Cox`, `Samuel Music Official`, `Singer Songwriter`]} />
    <Layout>

      <div className="parallax bg_sam2">
        <div className="content">

          <div className="signature_block">
            <img src={signature} width="491" height="151" alt="signature" />
            <br />
            <span>Singer / Songwriter</span>
          </div>

          <div className="blurb">
            <div className="blurb_image">
              <span>mic_none</span>
            </div>
            <div className="blurb_content">
              <h4>About Samuel</h4>
              <p>Samuel Cox is an 19 year old singer songwriter from Knoxville, TN. He loves playing music and writing songs. He plays several instruments, including the guitar, piano and ukulele.</p>
            </div>
          </div>

          <div className="blurb">
            <div className="blurb_image">
              <span>mic_none</span>
            </div>
            <div className="blurb_content">
              <h4>Musical Influences</h4>
              <p>Artists that have influenced Samuel&#8217;s unique style include Ed Sheeran, Twenty One Pilots, Sabrina Carpenter, John Mayer, Macklemore, and Shawn Mendes.</p>
            </div>
          </div>          

          <div className="blurb">
            <div className="blurb_image">
              <span>mic_none</span>
            </div>
            <div className="blurb_content">
              <h4>Venues Played</h4>
              <p>Samuel has played for small venues such as a local coffee house, to larger venues with audiences ranging from hundreds to over a thousand at places like Pigeon Forge&#8217;s Patriot Festival and Freed Hardeman University&#8217;s Horizons.</p>
            </div>
          </div>          

          <div className="blurb">
            <div className="blurb_image">
              <span>mic_none</span>
            </div>
            <div className="blurb_content">
              <h4>What Samuel is Working On</h4>
              <p>Samuel is currently working on producing his first songs and getting them ready for release, as well as producing music vidoes and covers of other artists&#8217; songs. He enjoys interacting with his fans on social media.</p>
            </div>
          </div>          
  
        </div>

      </div>

      <div className="parallax bg_media" >
        <a class="anchor" id="video_covers" href="/"><span>video covers</span></a>
        <div className="content">

          <div className="section_header">
            Music Video Covers
          </div>

          <div className="playlist">
            <div className="playlist_item"><Youtube id="zYrSntmvSu4" /></div>
            <div className="playlist_item"><Youtube id="X4y_Vl3PBWs" /></div>
            <div className="playlist_item"><Youtube id="bSGiouT-ee8" /></div>
            <div className="playlist_item"><Youtube id="qZqQNwmhdl8" /></div>
          </div>

        </div>
      </div>

      <div className="parallax bg_sam1" >
        <a class="anchor" id="loop_covers" href="/"><span>loop covers</span></a>
        <div className="content">

          <div className="section_header">
            Loop Covers
          </div>

          <div className="playlist">
            <div className="playlist_item"><Youtube id="w4tdX4P1Jdg" /></div>
            <div className="playlist_item"><Youtube id="L8LRkb_7OXQ" /></div>
            <div className="playlist_item"><Youtube id="wdOXpNfwpBc" /></div>
            <div className="playlist_item"><Youtube id="oQ2e8Hh5Q4Q" /></div>          
          </div>

        </div>
      </div>
    
      <div className="parallax bg_songs" >
        <a class="anchor" id="live_covers" href="/"><span>live covers</span></a>
        <div className="content">

          <div className="section_header">
            Live Covers
          </div>

          <div className="playlist">
            <div className="playlist_item"><Youtube id="bD2U-ac8zCw" /></div>
            <div className="playlist_item"><Youtube id="ND6CRCB4kRY" /></div>
            <div className="playlist_item"><Youtube id="9ctP2cLKE_A" /></div>
            <div className="playlist_item"><Youtube id="ngIbivrgdSw" /></div>
          </div>

        </div>
      </div>

    </Layout>
  </>
)

export default IndexPage
