import React from 'react'
import PropTypes from 'prop-types'
import './youtube.css'

class Youtube extends React.Component {
  state = {isLoaded: false}

  render() {
      if (!this.state.isLoaded) {
        return (
          <div className="youtube" onClick={() => this.play()}>
            <img src={`//i2.ytimg.com/vi/${this.props.id}/0.jpg`} alt="youtube video" />
            <div className="play-button"></div>
          </div>
        )
      } else {
        return (
          <div className="youtube">
            <iframe frameBorder="0" allowFullScreen="" src={`//www.youtube.com/embed/${this.props.id}?rel=0&showinfo=0&autoplay=1`} title={this.props.id} />
          </div>
        )
      }
  }

  play() {
    this.setState({isLoaded: true})
  }  
}

Youtube.propTypes = {
  id: PropTypes.string.isRequired,
}

export default Youtube